.modalHeaderContainer {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    width: 100%;
    height: 4vh;
    padding: 0 15px;
    /*box-shadow: 5px 5px 8px silver;*/
    z-index: 5000;
}

.closeModal {
    width: auto;
    cursor: pointer;
}

.url {
    margin: 0;
    font-weight: 500;
}


/* For pdf */
.react-pdf__Page {
    margin-top: 0px;
  }
  .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
  }
  
  .react-pdf__Page__annotations.annotationLayer {
    padding: 0px;
  }
  
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  
  /* For all pages */
  .allPagesContainer {
    background-color: grey;
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 4vh;
    height: 96vh;
    overflow: scroll;
  }
  
  
  

@media screen and (max-width:762px) {

    .allPagesContainer {
        margin-top: 5vh;
        height: 95vh;
    }
    
    .modalHeaderContainer {
        height: 5vh;
        padding: 0 15px;
    }

    .url {
        font-size: 100%;
    }

}
