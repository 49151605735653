body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#root {
  width: 100%;
  margin: 0;
  padding: 0;
}

h1 {
  margin-top: 30px !important;
  font-size: 24px !important;
  text-align: center;
}



.gm-style .gm-style-iw-c {
  /*padding-right: 10px !important;
  padding-bottom: 0px !important;
  max-width: 500px !important;
  max-height: 326px !important;
  min-width: 0px !important;
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate(-50%,-100%);
  background-color: #dd9191;
  border-radius: 8px;
  box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
  */
}




p.active,
p.active:hover {
  position: relative; /* Add this to ensure ::after is positioned correctly */
}

p.active:after,
p.active:hover:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 0; /* Change this to 0 to align with the left edge */
  position: absolute;
  background: #000;
  width: 100%; /* Change this to 100% to cover the full width */
}