.textCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    border-radius: 12px;
}

.Row:first-child {
    margin-top: 50px;
}

.Row {
    display: flex;
    justify-content: center !important;
    margin-bottom: 50px;
}

.Btn {
    color: white;
    border: none;
    background-color: #F56161;
    border-radius: 24px;
    border-color: none;
    padding: 0px;
    width: 100%;
}

.ColImg {
    max-width: 70%;
    width: auto;
    aspect-ratio: 1;
    object-fit: contain;
}

.BottomCard {
    background: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    padding: 20px;
}

.BtnNormal {
    color: white;
    border: none;
    background-color: #F56161;
    border-radius: 8px;
    border-color: none;
    padding: 10px;
    width: 90%;
    font-weight: 600;
}

@media screen and (max-width:762px) {

    .ColImg {
        max-width: 40%;
        margin-bottom: 30px;
    }
}