.cardContainerNews{
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    padding: 20px;
}

.newsImage {
    max-width: 120px;
    aspect-ratio: 1;
    object-fit: contain;
    /*background: #30303010;*/
    padding: 5px;
}

.newsTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.textBtn {
    cursor: pointer;
    font-weight: 600;
    text-align: center;
}

.btnContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.btnContainerCenter {
    display: flex;
    justify-content: flex-end;
}



@media screen and (max-width:762px) {

    .newsImageContainer {
        display: flex;
        justify-content: center;
    }

    .newsTextContainer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0px;
    }

    .cardContainerNews {
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 12px;
        padding: 10px;
    }

    .btnContainer {
        margin-top: 25px;
    }

    .btnContainerCenter {
        justify-content: center;
    }
}
