.container {
    max-width: 800px !important;
    text-align: center;
    font-size: 110%;
}

.rowContainer:first-child {
    margin-top: 30px !important;
}

.rowContainer:last-child{
    margin-top: 20px !important;
    margin-bottom: 50px !important;
}

.column {
    cursor: pointer;
    margin-top: 10px !important;
}