p.grey {
    color: rgb(162, 160, 160);
}
span {
    color: #303030;
}

.formContainer {
    width: 90%;
}

.formContainer input {
    width: 0%;
}

.formInputCustom {
    padding: 10px 20px;
    border-radius: 8px;;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 20px;
}

.formInputCustom label {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 90%;
    padding-left: 12px;
}

.formInputCustom input {
    color: black;
    margin-left: 12px;
    width: 90%;
    font-size: 110%;
    border: none;
}

.formInputCustom input::placeholder {
    color: lightgrey;
}

.formInputCustom input:focus {
    outline: none;
}


.qrreader {
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
}


.cancelScan {
    margin-top: -60px;
    z-index: 10000;
}

#swal2-title {
    font-size: 20px !important;
}

#swal2-html-container {
    font-size: 20px;
}
.swal2-popup { font-size: 0.7rem ! important; }


@media screen and (max-width:762px) {

    .qrreader {
        margin-top: 10px;
        margin-bottom: 0px;
        padding: 0px;
    }

    .cancelScan {
        margin-top: -70px;
        z-index: 10000;
    }   

    .formInputCustom {
        margin: 0px;
    }
}