.cardContainer {
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    padding: 15px 20px 5px 20px; 
    margin: 20px;
}


@media screen and (max-width:762px) {

    .cardContainer {
        margin: 10px 0px;
        padding: 15px 16px 5px 16px; 
        /*background: red;*/
    }

    .VorteilspartnerActions button {
        font-size: 90%;
        width: 120px !important;
    }
}


.VorteilspartnerContent {
    display: flex;
    flex-direction: column;
}

.VorteilspartnerActions {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 10px 0px;
}

.VorteilspartnerActions button {
    margin: 3px 2px;
}

.VorteilspartnerActions button {
    justify-content: space-around;
}