/*@font-face {
    font-family: "PoppinsBold";
    src: local("PoppinsBold"),
     url("../../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
     url("../../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 500;
}
*/

.ContainerHeader {
    margin-top: 20px !important;
} 

.iconHeaderContainer {
    margin-top: 25px !important;
}

.iconHeaderContainer {
    cursor: pointer;
    color: #520da7 !important;
    font-size: 110%;
    text-align: right;
}

.container {
    width: 100%;
}

.containerKacheln {
    width: 100%;
    max-width: 800px !important;
}

.rowContainer {
    padding: 0;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.rowContainer > col {
    justify-content: space-around !important;
}


.slogan {
    width: 100%;
    text-align: center;
    font-family: "Poppins";
    font-size: 23px;
    color: grey;
    margin: 100px 0px 50px 0px;
}   

.vinlogo {
    width: 80%;
    max-width: 350px;
}
.vinding {
    width: 80%;
    max-width: 450px;
    margin-top: 60px;
    margin-bottom: 30px;
}

.boldText {
    font-weight: bold;
    text-align: center;
}

.button {
    cursor: pointer;
    border-width: 0px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px 20px;
    color: rgb(157, 103, 168);
    background: rgb(248, 248, 248);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    text-align: center;
 }



.cardContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    aspect-ratio: 1;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
}








@media screen and (max-width:762px) {

    .cardContainer {
        margin: 20px 10px;
        padding: 0px;
        /*background: red;*/
        text-align: center;
    }
}

