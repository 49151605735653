.textCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    border-radius: 12px;
}

.Row:first-child {
    margin-top: 50px;
}

.Row {
    display: flex;
    justify-content: center !important;
    margin-bottom: 50px;
}

.Btn {
    color: white;
    border: none;
    background-color: #0d6efd;
    border-radius: 8px;
    border-color: none;
    padding: 6px;
    width: 100px;
}

.ColImg {
    max-width: 70%;
    width: auto;
    aspect-ratio: 1;
    object-fit: contain;
}

.BottomCard {
    background: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    padding: 20px;
}

.BtnNormal {
    color: white;
    border: none;
    background-color: #549E56;
    border-radius: 8px;
    border-color: none;
    padding: 10px;
    width: 100%;
    font-weight: 600;
}


.SelectInputContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.formContainer {
    width: 90%;
   
}

.formContainer input {
    width: 0%;   
}

.formInputCustom {
    padding: 10px 20px;
    border-radius: 8px;;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 20px;
}

.formInputCustom label {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 90%;
    padding-left: 12px;
}

.formInputCustom input {
    color: black;
    margin-left: 12px;
    width: 90%;
    font-size: 110%;
    border: none;
}

.formInputCustom input::placeholder {
    color: lightgrey;
}

textarea::placeholder {
    color: lightgrey;
}

.formInputCustom input:focus {
    outline: none;
}



@media screen and (max-width:762px) {

    .ColImg {
        max-width: 40%;
        margin-bottom: 30px;
    }


    
    .SelectInputContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100% !important;
        margin-bottom: 30px;
    }

    .InputRow {
        width: 100% !important;
        flex-direction: column;
        margin: 20px 0px 20px 0px !important; 
    }

    .selectContainer {
        width: 100% !important;
    }

    .removeBtn {
        margin: 10px 0 0 0;
    }
    

    
    .formInputCustom {
        padding: 10px 20px;
        border-radius: 8px;;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        margin-top: 20px;
    }

    .formInputCustom label {
        color: var(--primary-color);
        font-weight: bold;
        font-size: 90%;
    }

    .formInputCustom input {
        color: black;
        width: 100%;
        font-size: 110%;
        border: none;
    }

    .formInputCustom input::placeholder {
        color: lightgrey;
    }

    .formInputCustom input:focus {
        outline: none;
    }


    .customSelect {
        width: 100% !important;
    }

}