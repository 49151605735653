iframe {
    width: 100%;
    height: 96vh;
    background: grey;
    position: fixed;
    top: 4vh;
    display: block;
    overflow-y: hidden;
    z-index: 10;
}

.modalHeaderContainer {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    width: 100%;
    height: 4vh;
    padding: 0 15px;
    overflow-y: auto;
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;*/
}

.closeModal {
    width: auto;
    cursor: pointer;
}

.url {
    margin: 0;
    font-weight: 500;
}

@media screen and (max-width:762px) {

    iframe {
        height: 95vh;
    }
    
    .modalHeaderContainer {
        height: 5vh;
        padding: 0 15px;
    }

    .url {
        font-size: 100%;
    }

}
