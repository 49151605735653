.formInputCustom {
    padding: 10px 20px;
    border-radius: 8px;;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-top: 20px;
}

.formInputCustom label {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 90%;
    padding: 0px 12px;
}

.formInputCustom input {
    color: black;
    width: 100%;
    font-size: 110%;
    border: none;
    padding: 0px 12px;
}

.formInputCustom input::placeholder {
    color: lightgrey;
}

.formInputCustom input:focus {
    outline: none;
}

.BtnCustom {
    margin-top: 20px !important;
    background: var(--primary-color) !important;
    font-weight: bold !important;
    border-radius: 16px !important;
    padding: 15px 20px !important;
    width: auto !important;
    font-size: 110% !important;
    border: none !important
}