.spinner {
    font-size: 24px;
    color: white;
    margin-top: 30px;
}


@media screen and (max-width:762px) {
    .spinner{
        font-size: 20px;
        text-align: center;
        font-weight: 500;
        color: white;
        margin-top: 30px;
    }

}  