.cardContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
}

.cardContainerUpload {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;*/
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
}

.cardContainerSub {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px !important;
    padding: 10px 20px;
    margin: 8px 10px;
    cursor: pointer;
}

.cardImg {
   max-width: 150px;
   width: auto;
   object-fit: contain;
   aspect-ratio: 200/150;
   padding: 5px 10px;
  
}

.cardTitle {
    margin: 0;
    color: #520DA7;
}

.cardTitleSub {
    margin: 0;
    color: #520DA7;
    font-weight: 600;
}


@media screen and (max-width:762px) {

    .cardContainer {
        margin: 10px 10px;
        /*background: red;*/
    }

    .cardContainerSub {
        margin: 8px 8px;
        /*background: red;*/
    }

    .cardTitleSub {
        font-size: 90%;
    }
    
}