.headerContainer {
    display: flex;
    justify-content: center;
}

.headerInner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    max-width: 1000px;
    padding: 10px 0px;
}

.icon {
    cursor: pointer;
}

.onlyDesktop {
    display: inline-block;
}

@media only screen and (max-width: 600px) {
    .onlyDesktop {
        display: none;
    }
    
    .headerInner {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        padding: 10px 0px;
    }
}